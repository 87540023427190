.registerCode div:first-child {
	width: 150px;
	margin: auto auto 5px;
	border-radius: 1rem;
}

.registerCode div:first-child input {
	font-size: xx-large;
	padding: 22px 30px;
	text-align: center;
}
