.ValidationCode {
	box-shadow: 0px 4px 15px rgba(51, 51, 51, 0.2);
	outline: 1px solid var(--color-neutral-200);
	.ValidationCode__input div:first-child {
		width: 50px;
		border-radius: 0.75rem !important;

		input {
			padding: 5px;
			text-align: center;
			font-size: 1.7rem;
			font-weight: 800;
		}

		/* Hide the number input's spin button */
		/* Chrome, Safari, Edge, Opera */
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		input[type="number"] {
			-moz-appearance: textfield;
		}
	}
}
