.Calendar {
	--day-margin: 8px;

	.DayPicker-wrapper {
		padding: 0;
	}

	*:not(.DayPicker-Day--selected):not(.DayPicker-Day--outside) {
		color: #252c3d;
	}

	.DayPicker-WeekdaysRow {
		width: 100%;
		display: flex;
		justify-content: space-evenly;
		border-bottom: none;

		.DayPicker-Weekday {
			margin: var(--day-margin);
			width: calc(100% / 7);
		}
	}

	.DayPicker-Caption {
		font-size: var(--heading-03);
		font-weight: 800 !important;
		margin-top: -0.5rem;
		padding: 0 !important;
	}

	.DayPicker-Body {
		display: flex;
		flex-direction: column;
		overflow: hidden;

		.DayPicker-Week {
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			border-bottom: none;

			.DayPicker-Day {
				border-radius: 3rem !important;
				width: calc(100% / 7);
				text-align: center;
				line-height: 1;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: var(--day-margin) !important;
				aspect-ratio: 1;
				padding: 0;

				&.DayPicker-Day--outside {
					opacity: 0.5;
					cursor: pointer;
				}

				&.DayPicker-Day--selected:not(.DayPicker-Day--disabled) {
					background-color: var(--color-primary-500);
					color: white !important;
				}

				&.DayPicker-Day--selected.DayPicker-Day--outside {
					background-color: var(--color-primary-100);
					color: white !important;
				}

				&.DayPicker-Day--absences {
					background-color: var(--color-accent-500);
					opacity: 0.8;
					color: white;
					outline: 2px solid var(--color-accent-500);
					outline-offset: -2px;

					&.DayPicker-Day--waitingAbsences {
						outline-style: dashed !important;
						background-color: transparent;
						& > span {
							color: var(--color-accent-500) !important;
						}
					}

					&:not(:hover) > span {
						color: white;
					}

					&.DayPicker-Day--outside {
						opacity: 0.5;
						&:hover > span {
							color: white;
						}
					}

					&.DayPicker-Day--today {
						outline: 2px solid var(--color-primary-500) !important;
					}
				}

				&.DayPicker-Day--contracts {
					background-color: var(--color-primary-500);
					color: white;
					outline: 2px solid var(--color-primary-500);
					outline-offset: -2px;

					&:not(:hover) > span {
						color: white;
					}
					&.DayPicker-Day--outside {
						opacity: 0.5;
						&:hover > span {
							color: white;
						}
					}

					&.DayPicker-Day--today {
						outline: 2px solid var(--color-accent-500) !important;
					}
				}
			}
		}
	}

	/* Overload of zol css */
	.DayPicker:not(.DayPicker--interactionDisabled)
		.DayPicker-Day:not(.DayPicker-Day--disabled):not(
			.DayPicker-Day--selected
		):not(.DayPicker-Day--outside):not(.DayPicker-Day--selectedRange):not(
			.DayPicker-Day--hoverRange
		):not(.DayPicker-Day--absences):not(.DayPicker-Day--contracts):hover {
		outline: 2px solid var(--color-primary-300) !important;
		color: #252c3d !important;
	}

	.DayPicker-Day.DayPicker-Day--absences.DayPicker-Day--contracts {
		background: linear-gradient(
			-135deg,
			var(--color-accent-500) 50%,
			var(--color-primary-500) 50%
		);
		opacity: 0.9 !important;
	}

	.DayPicker-Day.DayPicker-Day--absences.DayPicker-Day--contracts:hover {
		& span {
			color: white !important;
		}
	}

	.DayPicker-Day--today {
		background-color: var(--color-neutral-400);
		color: white !important;

		& > span {
			color: white !important;
		}

		&:hover > span {
			color: var(--color-primary-500) !important;
		}
	}

	div[role="row"] {
		border-color: transparent !important;
	}
}
