@import "./constants/styles/fonts.css";
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "@zolteam/react-ras-library/dist/index.css";

html {
	background-color: var(--color-neutral-50);
	overflow: hidden;
}

body {
	overflow-x: auto;
	overflow-y: auto;
	/* margin: 0; */
	font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* -- Buttons & Inputs -- */

button:disabled {
	cursor: not-allowed !important;
	pointer-events: visible !important;
}

a:focus-visible {
	outline-color: var(--color-primary-400);
}

button:focus-visible {
	outline: 2px solid var(--color-primary-400) !important;
}

div[role]:focus-within {
	border-color: var(--color-primary-400) !important;
}

[class*="Field_input"].uppercase {
	text-transform: none;
	[role] {
		input {
			text-transform: uppercase;
		}

		input::placeholder {
			text-transform: none;
		}
	}
}

/* ordered lists */

ol {
	max-width: 350px;
	counter-reset: list-counter;
	list-style: none;
	padding-left: 40px;
}
ol li {
	margin: 0 0 0.5rem 0;
	counter-increment: list-counter;
	position: relative;
}

ol li::before {
	--size: 24px;
	content: counter(list-counter);
	color: var(--color-primary-600);
	font-size: 0.8rem;
	left: calc(-1 * var(--size) - 10px);
	line-height: var(--size);
	width: var(--size);
	height: var(--size);
	@apply absolute text-center top-0 rounded-full text-primary-600 bg-neutral-100 font-bold;
}

/* Toastify */

.Toastify__toast-container {
	width: 400px !important;
	font-family: var(--font-primary) !important;
}

.Toastify__toast {
	border-radius: var(--rad-8) !important;
	padding: var(--padding-s) var(--padding-m) !important;
	font-family: var(--font-primary) !important;
	font-size: var(--paragraph-01);
	line-height: var(--paragraph-01);

	&:hover {
		.Toastify__close-button {
			opacity: 1;
		}
	}
}

.Toastify__toast--success {
	background: var(--color-success-500) !important;
	color: white;
}

.Toastify__toast--error {
	background: var(--color-error-500) !important;
	color: white;
}

.Toastify__close-button {
	color: white;
	opacity: 0.7;
}

/*
** -- ScrollBar
*/

/* Width and height of the scrollbar */
/* WebKit browsers (Chrome, Safari, and Opera) */
* {
	--scrollbar-size: 6px;
}

::-webkit-scrollbar,
scrollbar {
	width: var(--scrollbar-size);
	height: var(--scrollbar-size);
}

/* Track - the area behind the scrollbar thumb */
/* WebKit browsers (Chrome, Safari, and Opera) */
::-webkit-scrollbar-track,
scrollbar-track {
	background: var(--color-primary-50);
	border-radius: var(--scrollbar-size);
	margin: 3px;
}

/* Thumb - the draggable scrolling element */
/* WebKit browsers (Chrome, Safari, and Opera) */
::-webkit-scrollbar-thumb,
scrollbar-thumb {
	border-radius: var(--scrollbar-size);
	background: #e0e0e0a1;
	background: var(--color-primary-400);
}

/* Corner - the bottom corner of the scrollbar, where both horizontal and vertical scrollbars meet */
/* WebKit browsers (Chrome, Safari, and Opera) */
::-webkit-scrollbar-corner,
scrollbar-corner {
	background: transparent;
}
