.DatePicker {
	width: 100%;
}

.react-datepicker {
	background-color: var(--background-color);
	color: var(--font-color);
	border: 3px solid var(--background-color);
	font-family: "DM Sans";
	font-size: 0.9rem;
}

.react-datepicker__time-container .react-datepicker__time {
	background: var(--background-color);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
	background-color: var(--color-primary-500) !important;
	color: white !important;
	border-radius: 50%;
	font-weight: bolder;
}

.react-datepicker select {
	background-color: #f7f7f7;
	border-radius: 0.3rem;
	padding: 0.2rem 0.2rem;
}

.react-datepicker__day--outside-month {
	opacity: 0.6;
}

.react-datepicker__navigation--previous {
	left: unset;
	right: 32px;
}

.react-datepicker__navigation--next {
	right: 0px;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
	display: flex;
	justify-content: space-evenly;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
	display: inline-block;
	margin: unset;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
	background-color: var(--color-primary-100);
	border-radius: 50%;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
	background-color: var(--color-primary-300);
}

.react-datepicker__day--in-selecting-range:not(
		.react-datepicker__day--in-range,
		.react-datepicker__month-text--in-range,
		.react-datepicker__quarter-text--in-range,
		.react-datepicker__year-text--in-range
	),
.react-datepicker__month-text--in-selecting-range:not(
		.react-datepicker__day--in-range,
		.react-datepicker__month-text--in-range,
		.react-datepicker__quarter-text--in-range,
		.react-datepicker__year-text--in-range
	),
.react-datepicker__quarter-text--in-selecting-range:not(
		.react-datepicker__day--in-range,
		.react-datepicker__month-text--in-range,
		.react-datepicker__quarter-text--in-range,
		.react-datepicker__year-text--in-range
	),
.react-datepicker__year-text--in-selecting-range:not(
		.react-datepicker__day--in-range,
		.react-datepicker__month-text--in-range,
		.react-datepicker__quarter-text--in-range,
		.react-datepicker__year-text--in-range
	) {
	background-color: var(--color-primary-200);
}

.react-datepicker__month--selecting-range
	.react-datepicker__day--in-range:not(
		.react-datepicker__day--in-selecting-range,
		.react-datepicker__month-text--in-selecting-range,
		.react-datepicker__quarter-text--in-selecting-range,
		.react-datepicker__year-text--in-selecting-range
	),
.react-datepicker__month--selecting-range
	.react-datepicker__month-text--in-range:not(
		.react-datepicker__day--in-selecting-range,
		.react-datepicker__month-text--in-selecting-range,
		.react-datepicker__quarter-text--in-selecting-range,
		.react-datepicker__year-text--in-selecting-range
	),
.react-datepicker__month--selecting-range
	.react-datepicker__quarter-text--in-range:not(
		.react-datepicker__day--in-selecting-range,
		.react-datepicker__month-text--in-selecting-range,
		.react-datepicker__quarter-text--in-selecting-range,
		.react-datepicker__year-text--in-selecting-range
	),
.react-datepicker__month--selecting-range
	.react-datepicker__year-text--in-range:not(
		.react-datepicker__day--in-selecting-range,
		.react-datepicker__month-text--in-selecting-range,
		.react-datepicker__quarter-text--in-selecting-range,
		.react-datepicker__year-text--in-selecting-range
	) {
	color: #000 !important;
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--selected {
	background-color: var(--color-primary-500);
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item:hover {
	background-color: var(--color-primary-200);
}

.react-datepicker__time-container
	.react-datepicker__time
	.react-datepicker__time-box
	ul.react-datepicker__time-list
	li.react-datepicker__time-list-item--selected:hover {
	background-color: var(--color-primary-500);
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
	border-radius: 5px;
	background-color: var(--color-primary-100);
}

.react-datepicker__header {
	background-color: #f0f0f0;
	background-color: var(--background-color);
	color: var(--font-color);
	border-bottom: 1px solid var(--color-primary-50);
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	text-transform: capitalize;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
	color: var(--font-color);
	border-radius: 50%;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.react-datepicker__time-container {
	border-left: 1px solid var(--color-primary-300);
}

.react-datepicker-popper[data-placement^="bottom"]
	.react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
	.react-datepicker__triangle::after {
	border-bottom-color: var(--background-color);
}

.react-datepicker-popper[data-placement^="top"]
	.react-datepicker__triangle::before {
	border-bottom-color: var(--background-color);
}

.react-datepicker-popper[data-placement^="top"]
	.react-datepicker__triangle::before {
	border-top-color: var(--background-color);
}

.react-datepicker-popper[data-placement^="top"]
	.react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
	.react-datepicker__triangle::after {
	border-top-color: var(--background-color);
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
	transform: rotate(135deg);
	right: -16px;
	top: 3px;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
	display: flex;
	justify-content: center;
	gap: 2rem;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	margin-top: -6px;
	margin-bottom: 10px;
	padding-left: 10px;
	text-align: left;
	position: relative;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
	border-color: var(--color-primary-400);
	border-width: 2px 2px 0px 0px;
}

.react-datepicker__navigation:hover *::before {
	border-color: var(--color-primary-300);
}
