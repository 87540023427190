.input div:first-child {
	background-color: #ffffff;
}
.input div:first-child > div {
	height: 20px;
}

.input div:first-child input {
	/* pointer-events: none; */
}

.buttonIcon {
	outline: none;
	border: none;
	background: transparent;
	position: absolute;
	right: 16px;
	top: 16px;
	cursor: pointer;
}

.inputContainer {
	position: relative;
}
.input {
	position: relative;
}
.icon {
	height: 22px;
	color: var(--color-neutral-500);
}
