.Loader {
	width: 100%;
	margin: auto;
	max-width: 350px;
	padding: 2%;

	.text {
		font-weight: 500;
		@apply text-primary-500 animate-pulse;
	}

	.Logo {
		position: relative;
		mask: url(../../../assets/myRAS.svg) no-repeat center / contain;
		aspect-ratio: 103/35;

		&[data-value="0"] svg > * {
			transition-duration: 0s;
		}

		&[data-value="100"] svg {
			top: -20% !important;

			* {
				fill: var(--color-primary-500) !important;
				fill-opacity: 1;
				transition-duration: 1s;
			}
		}

		svg {
			position: relative;
			top: 100%;
		}

		svg path {
			animation: pathAnim 7s;
			animation-timing-function: linear;
			animation-iteration-count: infinite;
			fill: var(--color-primary-500);
		}
	}

	&.rainbow {
		svg path {
			fill: #86007d;
		}

		svg path:nth-child(1) {
			fill: #0000f9;
		}

		svg path:nth-child(2) {
			fill: #008018;
		}
		svg path:nth-child(3) {
			fill: #ffff41;
		}
		svg path:nth-child(4) {
			fill: #ffa52c;
		}
		svg path:nth-child(5) {
			fill: #ff0018;
		}
		svg path:nth-child(6) {
			fill: #86007d;
		}
	}

	&.ocean {
		svg path {
			fill-opacity: 1;
		}

		svg path:nth-child(2) {
			fill: white;
		}

		svg path:nth-child(1) {
			fill: #69c5b9;
		}
		svg path:nth-child(3) {
			fill: #f95b03;
		}
	}

	&.ras {
		svg path:nth-child(1) {
			fill: red;
		}
	}
}

@keyframes pathAnim {
	0% {
		d: path(
			"M0,192	C220,100,440,100,660,192 C880,290,1100,290,1320,192	L1320 800 L0 800"
		);
	}
	25% {
		d: path(
			"M0,100	C220,100,440,250,660,250 C880,250,1100,100,1320,100	L1320 800 L0 800"
		);
	}
	50% {
		d: path(
			"M0,192	C220,290,440,290,660,192 C880,100,1100,100,1320,192	L1320 800 L0 800"
		);
	}
	75% {
		d: path(
			"M0,250	C220,250,440,100,660,100 C880,100,1100,250,1320,250	L1320 800 L0 800"
		);
	}
	100% {
		d: path(
			"M0,192	C220,100,440,100,660,192 C880,290,1100,290,1320,192	L1320 500 L0 800"
		);
	}
}
