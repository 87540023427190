div.react-select__control.react-select__control {
	height: fit-content !important;
	min-height: 50px;
	padding: 12px 20px !important;
}

div.react-select__value-container--has-value {
	gap: 0.25rem;
	margin-top: 0 !important;
}

div.react-select-async__value-container--has-value {
	margin-top: 0 !important;
}

div.react-select__control .react-select__multi-value {
	padding-right: 0.25rem !important;
}
div.react-select__multi-value {
	background-color: var(--color-primary-300);
	color: var(--color-primary-500);
	padding: 0px 4px !important;
	border-radius: 12px;
	position: relative !important;
}

div.react-select__control .react-select__input-container {
	margin: 2px !important;
}

div.react-select__multi-value:hover {
	filter: brightness(0.8) contrast(1.6) !important;
}

div.react-select__multi-value__label {
	padding-left: 2px !important;
	color: var(--color-primary-800);

	margin-top: 0px !important;
	z-index: 0;
	min-width: fit-content;
}

div.react-select__control .react-select__multi-value__label {
	padding-left: 0.5rem !important;
	margin-right: 0.25rem !important;
}

[class$="MultiValueRemove"] {
	width: 100%;
	justify-content: end;
	z-index: 1;
}

[class$="MultiValueRemove"]:hover {
	background-color: var(--color-primary-50) !important;
	background-color: transparent !important;
	color: var(--color-primary-400) !important;
	border-radius: 40px;
	padding-left: calc(100% - 14px) !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

div.react-select__option--is-focused,
div.react-select__option:hover {
	background-color: var(--color-primary-50) !important;
	font-weight: 600 !important;
	color: var(--color-primary-500) !important;
}

div.react-select__control--is-focused {
	border-color: var(--color-primary-400) !important;
	outline: none !important;
	box-shadow: none !important;
}
