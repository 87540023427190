.ImageViewer {
	width: 100%;
	height: 100%;
	background-color: white;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	transition-duration: 0.8s;
	z-index: 1000000;
}
