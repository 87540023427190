.header {
	display: contents;

	.logoWrapper {
		align-self: center;
		position: sticky;
		top: 20px;
		left: 20px;
		width: 150px;
	}

	.actionsWrapper {
		display: flex;
		justify-content: space-between;
		grid-column: 4 / full-end;
		align-self: center;
	}

	.logo {
		height: auto;
		padding-top: 0.11rem;
		width: 100%;
		color: var(--color-primary-500);
		@apply duration-700;
	}

	.headerList {
		display: flex;
		flex-flow: row nowrap;
		list-style: none;
		align-items: center;
		justify-content: flex-end;
		margin-left: auto;
	}

	.headerItem {
		margin: 0 var(--margin-s);
		padding: 0 var(--padding-s);
		cursor: pointer;
		font-size: var(--paragraph-02);
		display: flex;
		gap: 0.75rem;
		color: var(--color-neutral-500);
		font-weight: 700;
		display: flex;
		align-items: center;
	}

	.headerLink {
		text-transform: none;
		text-decoration: none;
		color: var(--color-neutral-500);
		font-size: var(--paragraph-01);
		font-weight: 700;
		transition: color 0.4s;

		&.active svg {
			color: var(--color-primary-500);
		}
	}

	.headerItem:hover,
	.headerLink:hover {
		color: var(--color-black);
	}

	.headerLink:hover svg {
		color: var(--color-black);
	}

	.headerLink:active {
		color: var(--color-neutral-300);
	}

	.active {
		color: var(--color-primary-500);
	}
}

@media screen and (min-width: 768px) {
	.header {
		display: contents;

		.logoWrapper {
			left: 40px;
		}

		.logo {
			width: 135px;
		}
	}
}
