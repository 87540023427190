@media screen and (max-width: 639px) {
	.Waves {
		position: fixed;
		transform: translateX(-50%) translateY(118%) rotateZ(-90deg);
		bottom: 0;
		transform-origin: top;
		width: 100% !important;
		height: 100vw !important;
		left: 0 !important;
		background-position: 100% 15% !important;
	}
}
