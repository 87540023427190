.Drawer {
	top: 100%;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: white;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	transition-duration: 0.8s;
	opacity: 0;
	z-index: 1000000;

	&.open {
		top: 0%;
		opacity: 1;
	}

	.Drawer_nav {
		@apply shadow-sm z-1;
	}
}
