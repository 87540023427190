.AdminLayout {
	height: 100%;
	background-color: var(--color-neutral-50);
	display: grid;
	grid-template-rows: 70px 1fr;
	grid-template-columns:
		[full-start] 20px
		[main-start]
		20px 20px
		1fr 20px
		1fr 20px
		1fr 20px
		1fr 20px
		1fr 20px
		1fr 20px
		1fr 20px
		1fr 20px
		1fr 20px
		1fr 20px
		1fr 20px
		1fr
		[main-end]
		20px [full-end];
	display: grid;

	.boxContent {
		display: grid;
		grid-template-columns:
			[full-start] 0
			[main-start]
			repeat(10, 1fr 20px)
			[main-end]
			1fr
			[full-end];
		grid-template-rows: [full-start] 0 [main-start] repeat(10, 1fr 20px) [main-end] 1fr [full-end];
		padding: 0;
		width: 100% !important;
		height: auto !important;
		min-height: 100% !important;
		background-color: var(--color-neutral-50);

		& > *:not(.Footer) {
			padding: 2.25rem !important;
		}

		& > .w-full {
			grid-column: full-start / full-end;
		}

		& > .h-full {
			grid-row: full-start/full-end;
		}

		&:not(:last-child:not(:visible)) {
			margin-right: 10px;
			border-top-right-radius: var(--rad-40);
			border-top-left-radius: var(--rad-40);
		}

		&.sideBox {
			display: none;
			min-width: 380px;
			max-width: min(500px, 24svw);
			width: 100% !important;
			overflow: auto;
		}
	}

	.layout-container {
		display: flex;
		grid-column: 1 / full-end;
	}

	.layout_header {
		display: contents;
		grid-column: main-start / main-end;
	}

	.menu {
		height: calc(100svh - 7.25rem);
		position: sticky;
		top: 7.25rem;

		& > div:first-child {
			max-height: 100%;
			overflow: auto;
			overflow-x: hidden;
			position: sticky;
			top: 10.25rem;
		}
	}
}

@media screen and (max-width: 639px) {
	.AdminLayout {
		.menu {
			max-width: 0%;
			transition-duration: 0.25s;
		}

		&.menu-open {
			overflow-x: hidden;
			.menu {
				max-width: 100%;
			}
		}

		.boxContent {
			border-top-right-radius: var(--rad-40) !important;
		}
	}
}

@media screen and (max-width: 1023px) {
	.boxContent {
		border-top-right-radius: 0px !important;
	}
}
