.Footer {
	font-size: 0.7rem;

	.footerLink {
		line-height: 0.8rem;

		&:hover {
			text-decoration: underline;
		}

		svg {
			width: 15px;
		}
	}

	.socials {
		svg:hover path {
			fill: var(--color-primary-100);
		}
	}
}
