.ViewerNavigation {
	@apply shadow-sm z-1;
	button.zoomBtn {
		@apply aspect-square rounded-4 bg-neutral-100;

		padding: 0.15rem !important;

		border-radius: 0.4rem !important;
		svg {
			margin: 0;
		}
	}
}
