.burgerMenuButton {
	& > div {
		opacity: 1;
		transition-duration: 0.25s;
	}

	&.active div {
		&:first-child {
			transform: rotate(45deg) translate(5px, 5px);
		}

		&:nth-child(2) {
			opacity: 0;
		}

		&:last-child {
			transform: rotate(-45deg) translate(5px, -5px);
		}
	}
}
