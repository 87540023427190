.MenuTab__container {
	margin: var(--margin-xs) 0;
	transition: background-color 0.4s;
	border-top-left-radius: var(--rad-32);
	border-bottom-left-radius: var(--rad-32);

	--transition-speed: 0.4s;
	--open-speed: 0.9s;
	--close-speed: 0.11s;

	& > button {
		padding: var(--padding-m) var(--padding-s) var(--padding-m)
			var(--padding-xl);
	}

	opacity: 0.8;

	.icon {
		filter: grayscale(1);

		svg {
			width: 1.75rem;
			height: 100%;
		}
	}

	.childs {
		transition: transform 0.3s ease-in-out, max-height 0.1s ease-in-out;
		opacity: 0;
		padding-right: var(--padding-m);
	}

	&.active {
		opacity: 1;
	}

	&.active .hideOnHover {
		font-weight: 700 !important;
		letter-spacing: 0.5px;
	}

	&:hover,
	&.active {
		background-color: var(--color-white);
	}

	&:hover .icon,
	&.active .icon {
		filter: grayscale(0);
	}

	&:hover .childs {
		top: 0;
		transform: scaleY(1);
		max-height: 31.25rem;
		transition-delay: 0s;
		transition: transform 0.15s ease, max-height 0.25s ease-in;
		padding-bottom: var(--padding-m);
		opacity: 1;
		gap: 0.75rem;
		display: flex;
		flex-direction: column;
	}

	.child {
		transition-duration: 0.15s;

		a {
			display: block;
			width: 100%;
		}

		&.active {
			font-weight: 600;
		}
	}
}

/* open state on mobile */
.Menu.open .MenuTab__container {
	&.active .childs {
		top: 0;
		transform: scaleY(1);
		max-height: 31.25rem;
		transition-delay: 0s;
		transition: transform 0.15s ease, max-height 0.25s ease-in;
		padding-bottom: var(--padding-m);
		opacity: 1;
		gap: 0.75rem;
		display: flex;
		flex-direction: column;
	}

	.hideOnHover {
		top: 0;
	}
}

@media screen and (min-width: 768px) {
	.MenuTab__container {
		&.active .childs {
			top: 0;
			transform: scaleY(1);
			max-height: 31.25rem;
			transition-delay: 0s;
			transition: transform 0.15s ease, max-height 0.25s ease-in;
			padding-bottom: var(--padding-m);
			opacity: 1;
			gap: 0.6rem;
			display: flex;
			flex-direction: column;
		}

		.hideOnHover {
			top: unset;
		}
	}
}
