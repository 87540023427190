.headerProfile {
	display: flex;

	&:hover .name,
	&:hover .icon {
		fill: var(--color-neutral-500);
		color: var(--color-neutral-500);
	}
	&:active .name,
	&:active .icon {
		fill: var(--color-neutral-300);
		color: var(--color-neutral-300);
	}

	.name {
		padding-right: var(--padding-s);
		transition: all 0.4s;
	}

	.icon {
		width: 0.6875rem;
		fill: var(--color-black);
		padding-right: var(--padding-s);
		transition: all 0.4s;
	}

	.image {
		width: 1.875rem;
		height: 1.875rem;
	}
}
