.Menu {
	--transition-speed: 0.3s;

	transition: width var(--transition-speed) ease-out;
	max-height: 100%;

	.hoverWrapper {
		transition: width var(--transition-speed) ease-in;

		&:hover {
			transition: width var(--transition-speed) ease-out;
		}

		&:hover .hideOnHover {
			opacity: 1;
			top: 0rem;
			transition: opacity var(--transition-speed) ease-in;
			transition-delay: 0.15s;
		}

		.tabWrapper {
			width: inherit;
		}

		.hideOnHover {
			transition: opacity var(--transition-speed) ease-out;
			transition-delay: 0.15s;
		}
	}

	/* open state on mobile */
	&.open {
		.hoverWrapper {
			transition: width var(--transition-speed) ease-out !important;
			width: 60vw !important;

			.hideOnHover {
				opacity: 1 !important;
				transition: opacity var(--transition-speed) ease-in;
				transition-delay: 0.15s;
			}
		}
	}
}

@media screen and (max-width: 639px) {
	.Menu:not(.open) {
		.hoverWrapper {
			width: 0px;
			transition: width var(--transition-speed) ease-out !important;
			overflow: hidden;

			.tabWrapper {
				overflow: hidden;
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.Menu {
		.hoverWrapper {
			transition: width var(--transition-speed) ease-out !important;
			width: 14rem !important;

			.hideOnHover {
				opacity: 1 !important;
				transition: opacity var(--transition-speed) ease-in;
				transition-delay: 0.15s;
			}
		}
	}
}
