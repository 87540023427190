.field {
	display: flex;
	align-items: center;
	padding: 0 21px 0 0;
	box-sizing: border-box;
	width: 100%;
	height: 52px;
	border-radius: var(--rad-32);
	position: relative;
	background-color: var(--color-white);
	transition: 0.3s background-color ease-in-out;
	border: 2px solid var(--color-neutral-200);
	transition: 0.3s border ease-in-out;
}

.field * {
	box-sizing: border-box;
}

.field input {
	width: 100%;
	height: 50px;
	position: relative;
	padding: 0px var(--padding-xl);
	border: none;
	border-radius: var(--rad-32);
	font-size: var(--paragraph-01);
	line-height: 16px;
	background-color: transparent;
	color: var(--color-black);
	font-family: var(--font-primary);
	box-sizing: border-box;
	outline: none;
	font-weight: 400;
	transition: 0.3s background-color ease-in-out, 0.1s padding ease-in-out;
	-webkit-appearance: none;
}
.field svg {
	cursor: pointer;
	height: 20px;
}

.field input:-webkit-autofill,
.field input:-webkit-autofill:hover,
.field input:-webkit-autofill:focus,
.field input:-webkit-autofill:active {
	-webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
	-webkit-transition-delay: 9999s;
}

.buttonPicto {
	display: flex;
	border: none;
	background: transparent;
	position: relative;
	top: 1px;
	padding: 1px 6px;
}
.buttonPicto {
	color: var(--color-neutral-700);
}
.buttonPicto:hover {
	color: var(--color-neutral-500);
}
.buttonPicto:active {
	color: var(--color-neutral-200);
}
.buttonPicto:focus {
	color: var(--color-neutral-500);
}

.empty .buttonPicto {
	pointer-events: none;
}
.empty .buttonPicto {
	color: var(--color-neutral-500);
}

.disabled {
	background: var(--color-neutral-50);
	pointer-events: none;
	cursor: default;
}
.disabled input {
	color: var(--color-neutral-300);
}
