.SectorTile {
	width: calc((100% / 4) - 0.75rem);
}

@media screen and (max-width: 1024px) {
	.SectorTile {
		width: calc((100% / 3) - 0.75rem);
	}
}

@media screen and (max-width: 768px) {
	.SectorTile {
		width: calc((100% / 2) - 0.75rem);
	}
}

@media screen and (max-width: 440px) {
	.SectorTile {
		width: 100%;
	}
}
