.labelSelect {
	top: 5px;
	opacity: 1;
	left: 22px;
}

.react-select__placeholder {
	margin-top: 14px !important;
}

.AdminLayout .agency-card {
	@apply bg-neutral-50  rounded-16;
}
