@font-face {
	font-family: "DM Sans";
	src: url("../../assets/fonts/DMSans-Regular.ttf");
	font-weight: 400;
}
@font-face {
	font-family: "DM Sans";
	src: url("../../assets/fonts/DMSans-Medium.ttf");
	font-weight: 500;
}

@font-face {
	font-family: "DM Sans";
	src: url("../../assets/fonts/DMSans-Bold.ttf");
	font-weight: 600;
}

@font-face {
	font-family: "DM Sans";
	src: url("../../assets/fonts/DMSans-ExtraBold.ttf");
	font-weight: 900;
}

:root {
	/* Primary font */
	--font-primary: "DM Sans", "Arial", sans-serif;

	--paragraph-03: 10px !important;
	--paragraph-02: 12px !important;
	--paragraph-01: 14px !important;
	--heading-03: 18px !important;
	--heading-02: 25px !important;
	--heading-01: 30px !important;
	--display-02: 40px !important;
}
