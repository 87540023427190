.AnimatedWaves {
	& * {
		animation-timing-function: ease-in-out;
	}

	.inner-cont {
		& > * {
			transition-duration: 5s;
		}

		.title,
		.text {
			max-width: 300px;
			line-height: 1.3;
		}

		.title {
			font-size: 25px; /* Safari fallback */
			font-size: clamp(22px, 25px, 2vw);
		}

		.text {
			font-size: 16px; /* Safari fallback*/
			font-size: clamp(14px, 16px, 1.5vw);
		}
	}

	.logo-mobile {
		width: max(7vw, 10vh);
	}
}

@media screen and (max-width: 639px) {
	.AnimatedWaves {
		transform: rotate(90deg);
		translate: -20% 50%;
		transform-origin: center;
	}
}

@media screen and (max-height: 350px) {
	.AnimatedWaves {
		.logo-mobile {
			opacity: 0;
		}
	}
}

@media screen and (max-width: 639px) {
	.AnimatedWaves {
		display: none !important;
	}
}
