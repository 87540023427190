.pictoNotification {
	display: flex;
	position: relative;

	&:hover .pictoNotificationIcon {
		color: var(--color-black);
	}

	&:active .pictoNotificationIcon {
		color: var(--color-neutral-300);
	}

	.pictoNotificationIcon {
		height: 1.375rem;
		width: 1.375rem;
		color: var(--color-neutral-500);
		transition: all 0.4s;
	}

	.pictoNotificationBadge {
		position: absolute;
		top: -0.5rem;
		right: -0.3125rem;
	}
}
