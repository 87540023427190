.InitLayout {
	&.isLoading {
		.StepsLinks li {
			@apply animate-pulse;
		}

		.step-link {
			color: transparent;
			&:before {
				display: none;
			}

			@apply bg-white opacity-50 rounded-8 h-2;
		}

		.StepsNav {
			@apply hidden;
		}

		.step {
			@apply animate-pulse;
		}
	}
}

/*
** StepWizard
*/
.StepWizard {
	& > *:not(.StepsNav) {
		margin-top: auto;
		margin-bottom: auto;
		padding-bottom: 1rem;
	}

	.step {
		transition-duration: 0.5s;
		transition-timing-function: ease-in-out;
		opacity: 0;
	}

	.step.active {
		opacity: 1;
	}

	/* use this to hide the scrollbar showed from other steps*/
	.step:not(.active) {
		max-height: 0vh !important;
		overflow: hidden;
	}
}

@media screen and (max-width: 767px) {
	.StepWizard {
		& > *:not(.StepsNav) {
			margin-top: unset;
		}
	}
}

.StepsLinks {
	--dot-size: 15px;

	.step-link:before {
		content: "";
		width: var(--dot-size);
		height: var(--dot-size);
		border-radius: var(--dot-size);
	}
}
