* {
	background-color: var(--neutral-200);
}

.App {
	height: 100svh;
	--font-color: "black";
}

.App.dark {
	background-color: var(--neutral-800);
	--font-color: "white";
}
