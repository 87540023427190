.PDFViewer {
	width: 100%;
	height: 100%;
	background-color: white;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	transition-duration: 0.8s;
	z-index: 1000000;

	.react-pdf__Document {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: max(0.5rem, calc(1rem * var(--viewer-scale-factor)));
		margin: auto;
		padding: 1rem;
		transition-duration: 1s;

		.react-pdf__Page {
			width: fit-content;
			margin-left: auto;
			margin-right: auto;
			transition-duration: 1s;
			border-radius: calc(0.8rem * var(--viewer-scale-factor));

			canvas {
				border-radius: calc(0.8rem * var(--viewer-scale-factor));
			}
		}
	}
}
